import 'picturefill';
import 'lazysizes';
import 'lazysizes/plugins/print/ls.print';
import 'objectFitPolyfill/dist/objectFitPolyfill.min';

// On lazyload
window.addEventListener('lazyloaded', (event) => {
  const { parentNode } = event.target;

  // Object-fit polyfill
  if (parentNode && (parentNode.classList.contains('image--cover') || parentNode.classList.contains('image--contain'))) {
    window.objectFitPolyfill(event.target);
  }

  // Add loading finished class
  if (parentNode && parentNode.classList.contains('loading')) {
    parentNode.classList.add('loading--finished');
  }
});

// Object-fit polyfill on window load
window.addEventListener('load', () => {
  window.objectFitPolyfill(document.querySelectorAll('.image--cover img'));
  window.objectFitPolyfill(document.querySelectorAll('.image--contain img'));
});
